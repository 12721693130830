@import '../../../styles/variables.scss';

.container {
  position: absolute;
  width: 100%;
  height: 220px;
  display: flex;
  a {
    text-decoration: none;
  }
  .logo {
    width: 330px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    img {
      margin-top: 15px;
      width: 160px;
    }
  }
  .hide-pad {
    display: none;
    @media only screen and (min-width: 1200px) {
      display: block;
    }
  }
  .background {
    position: relative;
    width: calc(100% - 330px);
    height: 100%;
    border-bottom-left-radius: 50px;
    background-color: $dark;
    background-image: url('../../../assets/images/fond.png');
    padding: 40px 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .nav {
      display: flex;
      align-items: center;
      a {
        @include text-button;
        display: flex;
        align-items: center;
        justify-content: center;
        border : none;
        position: relative;
        line-height: 29px;
        border-radius: $radius;
        letter-spacing: 1px;
        cursor: pointer;
        padding: 11px 30px 11px;
        color: $white;
        border: none;
        background: transparent;
        border: 1px solid $primary-color;
        margin-right: 16px;
        &.active {
          background: $primary-color;
        }
      }
    }
    .links {
      height: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .link {
        @include btn;
        @include label;
        color: $white;
        margin-right: 20px;
        display: flex;
        align-items: center;
        @media only screen and (min-width: 1280px) {
          margin-right: 20px;
        }
        svg {
          margin-right: 10px
        }
        &:hover {
          color: $primary-color;
        }
        &.is-active {
          color: $primary-color;
        }
      }
      .user {
        @include label;
        color: $white;
        display: flex;
        &:hover {
          color: $primary-color;
          .icon {
            color: $primary-color;
          }
        }
        p {
          min-width: 140px;
          text-align: right;
          display: none;
          @media only screen and (min-width: 1360px) {
            display: block;
          }
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          color: $white;
        }
      }
    }
    .period {
      position: absolute;
      top: 120px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100vw - 460px);
      .current {
        display: flex;
        align-items: center;
        .toggle {
          color: rgba($white , 0.5);
          border: 1px solid rgba($white , 0.3);
          border-radius: $radius;
          display: flex;
          width: 160px;
          &:hover {
            background-color: rgba($white , 0.1);
          }
          button {
            @include btn;
            @include label;
            padding: 10px 0px;
            width: 50%;
            color: rgba($white , 0.5);
            border: 1px solid transparent;
            border-radius: $radius;
            &.active {
              outline: 1px solid $white;
              color: $dark;
              background-color: $white;
            }
          }
        }
        .select {
          margin-left: 15px;
          width: 180px;
          height: 40px;
        }
      }
      .select {
        width: 180px;
        height: 40px;
      }
    }
  }
}