@import '../../../styles/variables.scss';

.date-picker{

  label{
    @include label;
  }

  &.inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+.container-input{
      margin-top: 10px;
    }
  }

  .container-input {
    position: relative;
    width: 100%;
    .container-icon{
      display: block;
      position: absolute;
      top:0;
      right:0;
      height: 36px;
      width: 36px;
      pointer-events: none;
      >svg {
        color: rgba($white, 0.6);
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
      }
    }

    >div{
      width: 100%;
    }
    &.primary {
      input {
        border-radius: $radius;
        min-height: 50px;;
        background-color: rgba($white , 0.2);
        border: 2px solid #ECECF0;
        color: $black;
        @include label;
      }
      label {
        color: $black;
      }
    }
  }

  .day {
    @include transition;
    color: $dark;
    border-radius: 30px;
    &.selected{
      background-color: $primary-color;
      color : white;
    }

    &:not(.selected){
      background-color: transparent;
      &:hover {
        background-color: rgba($primary-color, 0.3);
      }
    }
  }

  input{
    @include transition;
    display: block;
    padding: 10px;
    border: none;
    outline: none;
    background-color: transparent;
    border-radius: 0px;
    width: 100%;
    // min-width: 250px;
    box-sizing: border-box;
    @include text;
    &:focus{
      border: solid 1px $primary-color;
    }
    &::placeholder {
      @include text;
      color : #a3a3a3;
    }
  }

}
