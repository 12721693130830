@import '../../../styles/variables.scss';

.container {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  .toggle {
    transform: translate(18px, 18px);
    position: absolute;
    display: flex;
    align-items: center;
    @include bold;
    text-transform: uppercase;
    z-index: 3;
    button {
      @include btn;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;
      background-color: rgba(#D5D5DA, 0.3);
      border-radius: 8px;
      margin-right: 6px;
      &:hover {
        background-color: rgba(#D5D5DA, 0.5);
      }
    }
  }
  .graph {
    width: 100%;
    max-width: 100%;
    height: calc(38vh);
    min-height: 320px;
    z-index: 1;
  }
  .table {
    width: 100%;
    height: 100%;
    padding-top: 30px;
    z-index: 1;
  }
}