@import '../../../styles/variables.scss';

.container {
  padding: 10px 10px;
  background-color: $white;
  border-radius: $radius;
  box-shadow: $shadow;
  overflow-y: auto;
  @include scrollbar;
  @media only screen and (min-width: 1150px) {
    padding: 20px 40px;
  }
  .content {
    padding-bottom: 20px;
    animation-name: fade;
    animation-duration: 0.2s;
    @include animation-mixin-y(fade , 30px , 0px);
    .row {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: flex-start;
      &.labels {
        height: 40px;
        .col {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px 20px;
        }
      }
      .col {
        box-sizing: border-box;
        width: 20%;
        height: 100%;
        padding: 10px 10px;
        margin: 0px;
        @include label;
        &.name {
          width: calc(60% - 12px);
        }
        input {
          min-width: 100px;
        }
        &:not(:first-child) {
          border-left: 2px solid #D5D5DA;
        } 
      }
    }
    .btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      padding: 20px;
    }
  }
}