@import '../../../styles/variables.scss';

.container {
  width: 100%;
  height: calc(100vh - 160px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include scrollbar;
  @media only screen and (min-width: 1150px) {
    flex-direction: row;
  }
  .synthesis {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    // @media only screen and (min-width: 1150px) {
    //   width: 50%;
    //   height: 100%;
    // }
    // @media screen and (min-width: 1450px ) {
    //   width: 60%;
    // }
  }
  // .notifs {
  //   width: 100%;
  //   @media only screen and (min-width: 1150px) {
  //     width: 50%;
  //     height: 100%;
  //   }
  //   @media screen and (min-width: 1450px ) {
  //     width: calc(40% - 40px);
  //     height: 100%;
  //   }
  // }
}