@import '../../../styles/variables.scss';

.container {
  display: flex;
  background: rgba(28, 55, 107, 0.0525295);
  border-radius: 6px;
  margin-bottom: 20px;
  color: $black;
  text-decoration: none;
  @include transition;
  p {
    width: 30%;
    margin: 8px;
    padding: 8px 20px;
    @include label;
    &.admin {
      width: 50%;
      padding-left: 20px;
      color: #767690;
      border-left: 2px solid $white;
    }
  }
  .icon {
    width: 20%;
    padding-right: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $primary-color;
    @include btn;
    @include transition;
    &:hover {
      color: $dark;
    }
  }
}