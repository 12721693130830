@import '../../../styles/variables.scss';

.container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column-reverse;
  @media only screen and (min-width: 1150px) {
    flex-direction: row;
  }
  label {
    @include label;
  }
  .list {
    width: 100%;
    @media only screen and (min-width: 1150px) {
      width: 65%;
    }
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      button {
        @include btn;
      }
    }
    .panel {
      overflow: hidden;
      @include transition;
      .content {
        padding-bottom: 20px;
      }
    }

  }
  .total {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    background: rgba(28, 55, 107, 0.0525295);
    border-radius: 6px;
    margin-bottom: 20px;
    @media only screen and (min-width: 1150px) {
      width: calc(35% - 20px);
      margin-bottom: 0px;
    }
    p {
      margin: 0px;
    }
    .value {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 20px;
      h3 {
        @include bold;
        font-size: 43px;
        @media only screen and (min-width: 1150px) {
          font-size: 36px;
        }
        @media only screen and (min-width: 1300px) {
          font-size: 43px;
        }
        line-height: normal;
        color: $primary-color;
        margin: 0px;
      }
    }
    .percentage {
      @include bold;
      text-align: left;
      @media only screen and (min-width: 1150px) {
        text-align: right;
      }
      font-size: 24px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .provisional {
      p {
        margin-top: 5px;
        @include bold;
        font-size: 24px;
      }
    }
  }
}