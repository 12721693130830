@import '../../../styles/variables.scss';

$transition: .3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
$width: 52px;
$height: 22px;

.container {
  padding: 0px 20px;
  min-height: 400px;
  height: auto;
  h2 {
    margin: 0px;
    text-align: center;
    color: $primary-color;
    font-family: "Montserrat-Bold";
    font-size: 32px;
  }
  label {
    @include label;
    display: block;
    margin-top: 20px;
  }
  .select {
    margin-top: 20px;
  }
  .month-toggle {
    margin-top: 20px;
    color: rgba($white , 0.5);
    border: 1px solid rgba($dark , 0.3);
    border-radius: $radius;
    display: flex;
    width: 160px;
    &:hover {
      background-color: rgba($dark , 0.1);
    }
    button {
      @include btn;
      @include label;
      padding: 10px 0px;
      width: 50%;
      color: rgba($dark , 0.5);
      border: 1px solid transparent;
      border-radius: $radius;
      &.active {
        outline: 1px solid $dark;
        color: $white;
        background-color: $dark;
      }
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-height: 250px;
    margin-top: 10px;
    overflow-y: auto;
    @include scrollbar;
    @media screen and (max-height: 950px) {
      max-height: 100px;
    }
    .list-item {
      @include bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #F9F9FA;
      border: 2px solid #ECECF0;
      border-radius: 9px;
      padding: 6px 10px;
      margin-bottom: 6px;
      margin-right: 10px;
      p {
        margin: 0px;
      }
      button {
        @include btn;
        height: 30px;
        min-width: 30px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        padding: 0px;
        &:hover {
          background-color: #ECECF0;
        }
      }
    }
  }
  .file {
    margin-top: 30px;
    .type {
      display: flex;
      align-items: center;
      @include label;
      p {
        margin: 0px;
        opacity: 0.4;
        transition: $transition;
        &.active {
          opacity: 1;
        }
      }
      .toggle {
        margin: 10px;
        .toggle-control {
          transition: $transition;
          width: $width;
          height: $height;
          margin: 0px 10px;
          display: block;
          border: 2px solid #dddddd;
          box-sizing: border-box;
          border-radius: 7px;
          position: relative;
          cursor: pointer;
          &:after{
            transition: $transition;
            content: "";
            width: 22px;
            height: 21px;
            display: block;
            background: #33B0C5;
            border: 2px solid #1D8091;
            box-sizing: border-box;
            box-shadow: 0px 2px 4px rgba(24, 24, 68, 0.287562);
            border-radius: 8px;
            position: absolute;
            top: -1px;
            left: 0;
          }
        }
        
        input{
          display: none;
          &:checked + .toggle-control{
            &:after{
              left: $width/2;
            }
          }
        }
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px ;
  }
}