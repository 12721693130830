@import '../../../styles/variables.scss';

.container {
  width: 100%;
  // min-height: 610px;
  // height: calc(100vh - 220px);
  // background-color: $white;
  // border-radius: $radius;
  // box-shadow: $shadow;
  padding: 20px 30px;
  margin-right: 0px;
  margin-bottom: 40px;
  // overflow-y: auto;
  // @include scrollbar;
  // @media screen and (max-width: 1450px ) {
  //   height: auto;
  //   overflow-y: hidden;
  // }
  label {
    text-transform: uppercase;
    @include label;
    @include bold;
    font-size: 14px;
  }
  .notifications {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Montserrat-SemiBold";
    .textarea {
      padding-bottom: 10px;
      margin-top: 10px;
      padding: 10px 15px 20px 15px;
      background: rgba(28, 55, 107, 0.0525295);
      border-radius: 6px;
      color:#767690;
      font-family: "Montserrat-SemiBold";
      textarea {
        margin-top: 10px;
      }
    }
    .btn {
      display: flex;
      justify-content: flex-end;
      position: relative;
      button {
        position: absolute;
        bottom: -10px;
        right: 20px;
      }
    }
  }
}