@import '../../../styles/variables.scss';

.container {
  width: 100%;
  display: flex;
  align-items: center;
  background: $white;
  border-bottom: 2px solid #D5D5DA;
  @include bold;
  .col {
    width: calc(70% / 3);
    height: 100%;
    text-align: left;
    padding: 0px 10px;
    display: flex;
    justify-content: flex-start;
    opacity: 0.3;
    p {
      margin: 8px 0px;
    }
    &.name {
      width: 30%;
      opacity: 1;
    }
    &.amount {
      display: flex;
      justify-content: flex-end;
    }
  }
}