@import '../../../styles/variables.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(28, 55, 107, 0.0525295);
  border-radius: 6px;
  margin-bottom: 20px;
  color: $black;
  text-decoration: none;
  @include transition;
  .col {
    width: 25%;
    padding: 4px 20px;
    p {
      @include label;
    }
    &.name {
      width: 50%;
    }
    &.actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .download {
        @include btn;
        @include label;
        color: $primary-color;
        margin-right: 10px;
      }
      .delete {
        @include btn;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba($dark , 0.5);
        &:hover {
          color: $danger;
        }
      }
    }
  }
  
}