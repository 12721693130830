@import '../../../styles/fonts-style.scss';
@import '../../../styles/variables.scss';

.container-textarea{
  margin: 5px 0px 0px 0px;

  label{
    font-size: 14px;
  }

  &.inline{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+.container{
      margin-top: 10px;
    }
  }

  &.disabled{
    pointer-events: none;
    opacity: .5;
  }

  .container{
    width: 100%;
    min-width: 250px;

    .textarea{
      width: 100%;
      border: none ;
      outline: none;
      font-family: sans-serif;
      padding: 10px !important;
      border-radius: 6px;
      box-sizing: border-box;
      resize: none;
      overflow-y: hidden;
      border: solid 1px #dcdcdc;
      @include text;

      &:focus{
        border: solid 1px $primary-color;
      }

      &::placeholder {
        @include text;
      }

      &.disabled{
        pointer-events: none;
      }

    }

    .count-characters{
      text-align: right;
      font-size: 12px;
      margin: 0;
      padding: 0;
    }

  }





}
