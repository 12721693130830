@import '../../../styles/variables.scss';

.container {
  width: calc(50% - 20px);
  padding: 30px;
  background-color: $white;
  border-radius: $radius;
  box-shadow: $shadow;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 0px;
  margin-bottom: 40px;
  label {
    text-transform: uppercase;
    @include label;
    @include bold;
    span {
      opacity: 0.3;
    }
  }
  h3 {
    @include bold;
    font-size: 30px;
    line-height: normal;
    color: $primary-color;
    margin: 15px 0;
    svg {
      transform: translateY(3px);
    }
  }
  h4 {
    @include bold;
    font-size: 18px;
    margin: 5px 0px 0px 0px;
    svg {
      transform: translateY(3px);
    }
  }
  .row {
    display: flex;
    align-items: flex-end;
    &.years {
      margin-top:10px;
      color: #D5D5DA
    }
    .col {
      label {
        @include label;
        text-transform: none;
      }
      min-width: 35%;
      margin-right: 20px;
    }
  }
  // @media only screen and (min-width: 1150px) {
  //   width: calc(100% - 40px);
  // }
  @media screen and (min-width: 1450px ) {
    width: calc(50% - 20px);
  }
  &.is-inline {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 1400px) {
      width: 100%;
    }
    .inline {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      h3 {
        font-size: 36px;
        width: 65%;
        margin: 0px
      }
      .row {
        width: 35%;
        margin-bottom: 0px;
        .col {
          label {
            @include label;
            text-transform: none;
          }
          min-width: 100px;
          margin-right: 20px;
        }
      }
    }
  }
}