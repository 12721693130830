@import '../../../styles/variables.scss';

.button{
    @include text-button;
    display: flex;
    align-items: center;
    justify-content: center;
    border : none;
    position: relative;
    line-height: 29px;
    border-radius: $radius;
    letter-spacing: 1px;
    cursor: pointer;
    min-width: 155px;
    padding: 11px 30px 11px;
    &.xs {
      padding: 4px 20px 4px 20px;
      min-width: 0px;
    }
    svg{
        margin-left: 15px;
    }

    &.primary{
        color: $white;
        border: none;
        background: $primary-color;
        &.loading{
          pointer-events: none;
          color : rgba(255,255,255,.6);
          background: darken($primary-color,3%) repeating-linear-gradient(60deg, transparent, transparent 10px, $primary-color 10px, $primary-color 20px);
          background-repeat: repeat;
          background-size:calc(100% + 20px) 51px;
	        background-position-x:0%;
          background-repeat:repeat-x;
	        animation: backgroundMove .6s infinite linear forwards;
        }
        &:not(.loading){
            background: $primary-color;
        }
    }

    &.secondary{
        color: $white;
        border: none;
        background: $dark;
        &.loading{
          pointer-events: none;
          color : rgba(255,255,255,.6);
          background: darken($dark,3%) repeating-linear-gradient(60deg, transparent, transparent 10px, $dark 10px, $dark 20px);
          background-repeat: repeat;
          background-size:calc(100% + 20px) 51px;
	        background-position-x:0%;
          background-repeat:repeat-x;
	        animation: backgroundMove .6s infinite linear forwards;
        }
    }


    @keyframes backgroundMove {
        0%{
            background-position-x:0%;
        }
        100% {
            background-position-x:-21px;
        }
    }

}