@import '../../../styles/variables.scss';

.container {
  width: 100%;
  height: calc(100vh - 160px);
  overflow-y: auto;
  overflow-x: hidden;
  @include scrollbar;
  .synthesis {
    width: 100%;
    height: 180px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 1400px) {
      flex-wrap: nowrap ;
      flex-direction: column;
      height: auto;
      margin-bottom: 0px;
    }
  }
  .block {
    width: 100%;
    background-color: $white;
    border-radius: $radius;
    box-shadow: $shadow;
    &:not(:last-child) {
      margin-bottom: 30px;
    } 
  }
}