@import '../../../styles/variables.scss';

.container {
  padding: 0px 20px;
  min-height: 300px;
  height: auto;
  h2 {
    margin: 0px;
    margin-bottom: 20px;
    text-align: center;
    color: $primary-color;
    @include title-4;
  }
  label {
    @include label;
    display: block;
    margin-top: 20px;
  }
  .file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 200px;
    margin: 5px 0px;
    text-transform: none;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    font-size: 14px;
    background: rgba(28, 55, 107, 0.0525295);
    border-radius: 3px;
    .link {
      display: flex;
      align-items: center;
      color: $dark;
      p {
        margin: 0px;
        text-decoration: underline;
        @include bold;
        color: $dark;
      }
    }
    .delete {
      @include btn;
      @include text;
      font-size: 12px;
      display: flex;
      align-items: center;
      p {
        color: red;
        font-family: "Montserrat-SemiBold";
        margin: 0px;
      }
      svg {
        margin-right: 5px ;
        fill: red;
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px ;
  }
}