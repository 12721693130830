@import '../../../styles/variables.scss';

.container {
  width: 100%;
  margin-top: 10px;
  padding: 10px 15px;
  background: rgba(28, 55, 107, 0.0525295);
  border-radius: 6px;
  color:#767690;
  font-family: "Montserrat-SemiBold";
  &.is-anwser {
    background: $white;
    border: 2px solid rgba(24, 24, 68, 0.0669799);
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .profile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0px;
      }
      svg {
        margin-right: 8px;
      }
    }
    button {
      padding: 0px;
      @include btn;
    }
  }
  .panel {
    overflow: hidden;
    @include transition;
    .content {
      padding-bottom: 5px;
      p {
        font-size: 12px;
      }
      .answer-container {
        .answer {
          padding: 10px;
          margin-bottom: 10px;
          background: rgba(28, 55, 107, 0.0525295);
          border-radius: 6px;
        }
      }
      .textarea {
        padding-bottom: 10px;
      }
      .btn {
        display: flex;
        justify-content: flex-end;
        position: relative;
        button {
          position: absolute;
          bottom: 12px;
          right: 20px;
        }
      }
    }
  }
}