@import '../../../styles/variables.scss';

.container {
  header {
    padding: 30px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      @include title-1;
      color: $primary-color;
      margin: 0px;
    }
    a {
      display: flex;
      align-items: center;
      @include title-4;
      font-size: 14px;
      color: rgba($dark, 0.6);
      svg {
        color: rgba($dark, 0.6);
      }
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    padding: 0px 40px;
    .search {
      width: 480px;
    }
  }
  .list {
    margin-top: 20px;
    height: calc(100% - 170px);
    padding: 10px 27px 20px 40px;
    overflow-y: auto;
    @include scrollbar;
  }
}