@import '../../../styles/variables.scss';

.container {
  padding: 10px 30px;
  background-color: $white;
  border-radius: $radius;
  box-shadow: $shadow;
  overflow-y: auto;
  @include scrollbar;
  @media only screen and (min-width: 1150px) {
    padding: 20px 40px;
  }
}