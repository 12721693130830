@import '../../../styles/variables.scss';

.container {
  width: calc(100%);
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background: $white;
  margin: 0px;
  @include label;
  &:nth-child(2n) {
    background-color: rgba($black, 0.05);
  }
  &.active {
    outline: 2px solid $primary-color;
  }
  .label {
    width: 30%;
    height: 100%;
    text-align: right;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
  }
  .input {
    width: 35%;
    height: 100%;
    padding: 4px 0px;
    display: flex;
    justify-content: flex-end;
    border-left: 2px solid #D5D5DA;
    input {
      text-align: right;
      background-color: transparent;
      border: none;
      width: 80%;
      padding: 8px;
      margin: 2px;
      outline: none;
      border-radius: 6px;
      @include label;
      @include transition;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
      &:focus {
        background: rgba($primary-extra-light, 0.4);
      }
    }
    .unit {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 55px;
    }
  }

}