@import '../../../styles/variables.scss';

.container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  @include scrollbar;
  .block {
    width: 100%;
    background-color: $white;
    border-radius: $radius;
    box-shadow: $shadow;
    .tables {
      padding-top: 25px;
    }
    &.water {
      height: calc(38vh);
      min-height: 360px;
    }
    &.month {
      width: calc(50% - 20px);
    }
    &:not(:last-child) {
      margin-bottom: 30px;
    } 
    &.bars {
      min-height: 0px;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: transparent;
      border-radius: 0px;
      margin-bottom: 0px;
      box-shadow: none;
    }
  }
}