@import '../../../../styles/variables.scss';

.container {
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    p {
      @include bold;
      text-transform: uppercase;
      margin: 5px 0px;
    }
  }
  .chart-container {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    .chart {
      width: 60%;
      height: 100%;
    }
    .legend {
      width: 40%;
      display: flex;
      align-items: center;
      .item {
        margin-bottom: 20px;;
        label {
          @include label;
        }
        p {
          margin-top: 5px;
          @include bold;
          font-size: 24px;
        }
      }
    }
  }
}