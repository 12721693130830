@import '../../../../styles/variables.scss';

.container {
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    p {
      @include bold;
      min-width: 30%;
      text-transform: uppercase;
      margin: 5px 0px;
    }
    .legend {
      min-width: 60%;
      display: none;
      @media only screen and (min-width: 1180px) {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
      }
      .key {
        display: flex;
        align-items: center;
        padding-left: 30px;
        p {
          @include label;
          text-transform: initial;
          line-height: normal;
        }
        .square {
          width: 14px;
          height: 14px;
          margin-right: 10px;
          border-radius: 3px;
        }
      }
    }
  }
  .total {
    position: absolute;
    top: 50px;
    display: flex;
    align-items: flex-end;
    p {
      margin: 0px;
      line-height: normal;
      margin-right: 10px;
      @include label;
      &.value {
        @include bold;
        line-height: 25px;
        font-size:26px;
        color: $primary-color;
        svg {
          transform: translate(-4px, 3px);
        }
      }
    }
  }
  .chart-container {
    width: 100%;
    height: calc(100% - 40px);
    overflow-y: hidden;
    overflow-x: auto;
    @include scrollbarX;
    .chart {
      width: 100%;
      height: 100%;
    }
  }
}