@import '../../../styles/variables.scss';

.container {
  padding: 30px 40px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($primary-color, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $primary-color;
  }
  header {
    .title {
      display: flex;
      h1 {
        @include title-1;
        color: $dark;
        margin: 0px 10px;
      }
      a {
        color: $primary-color;
        svg {
          @include transition;
        }
        &:hover {
          svg {
            transform: translateX(-5px);
          }
        }
      }
    }
  }
  label {
    @include label;
    font-size: 16px;
  }
  .users {
    width: 100%;
    padding: 20px 0px;
    label {
      @include label;
    }
    .list {
      margin: 20px 0px;
    }
  }
}