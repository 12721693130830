@import '../../../styles/variables.scss';

$transition: .3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
$width: 52px;
$height: 22px;

.container {
  position: absolute;
  top: 148px;
  padding-left: 40px;
  opacity: 0;
  @include transition;
  &.active {
    opacity: 1;
  }
  .select {
    margin-bottom: 20px;
    max-width: 250px;
  }
  h4 {
    text-transform: uppercase;
    margin: 0px;
    font-size: 30px;
    @include bold;
  }
  .provisional {
    display: flex;
    align-items: center;
    @include label;
    p {
      opacity: 0.4;
      transition: $transition;
      &.active {
        opacity: 1;
      }
    }
    .toggle {
      .toggle-control {
        transition: $transition;
        width: $width;
        height: $height;
        margin: 0px 10px;
        display: block;
        border: 2px solid #dddddd;
        box-sizing: border-box;
        border-radius: 7px;
        position: relative;
        cursor: pointer;
        &:after{
          transition: $transition;
          content: "";
          width: 22px;
          height: 21px;
          display: block;
          background: #33B0C5;
          border: 2px solid #1D8091;
          box-sizing: border-box;
          box-shadow: 0px 2px 4px rgba(24, 24, 68, 0.287562);
          border-radius: 8px;
          position: absolute;
          top: -1px;
          left: 0;
        }
      }
      
      input{
        display: none;
        &:checked + .toggle-control{
          &:after{
            left: $width/2;
          }
        }
      }
    }
  }
  nav {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    @media only screen and (min-height: 700px ) {
      margin-top: 50px;
    }
    @media only screen and (min-height: 800px ) {
      margin-top: 100px;
    }
    a {
      @include transition;
      @include label;
      padding: 12px 0px;
      font-size: 16px;
      text-decoration: none;
      color: rgba($black, 0.4 );
      &:hover {
        color: $primary-color;
      }
      &.active-link {
        color: $black;
      }
    }
  }
}