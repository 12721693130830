@import '../../../styles/variables.scss';

.container {
  width: 100%;
  border-bottom: 1px solid rgba($black , 0.2);
  animation-name: fade;
  animation-duration: 0.2s;
  @include animation-mixin-y(fade , 30px , 0px);
  .title {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    h2 {
      @include bold;
      text-transform: uppercase;
    }
    button {
      @include btn;
      width: 38px;
      height: 38px;
      display: flex;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: rgba($black , 0.1);
      }
    }
  }
  .panel {
    overflow: hidden;
    @include transition;
    .content {
      margin-bottom: 20px;
      padding: 2px 2px 20px 2px;
      .labels {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .label {
          width: 35%;
          height: 40px;
          padding: 4px 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-left: 2px solid #D5D5DA;
          margin: 0px;
          @include label;
        }
      }
    }
  }
}