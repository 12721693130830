@import '../../../styles/variables.scss';

.container {
  padding: 0px 0px;
  overflow-y: auto;
  @include scrollbar;
  .lists {
    width: 100%;
    min-height: 100%;
    padding: 20px 30px;
    background-color: $white;
    border-radius: $radius;
    box-shadow: $shadow;
  }
}