@import '../../../styles/variables.scss';

.container {
  width: 100%;
  padding: 20px 0px;
  label {
    @include label;
  }
  .checklist {
    margin-top: 20px;
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    .checkbox {
      width: calc(25%);
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      z-index: 1;
      label {
        display: block;
        max-width: calc(100% - 40px);
        padding-right: 10px;
        pointer-events: none;
        margin-left: 8px ;
        z-index: 0;
      }
      input[type="checkbox"] {
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
            appearance: none;
        width: 22px; height: 22px;
        background: #FFFFFF;
        border: 2px solid #ECECF0;
        box-sizing: border-box;
        border-radius: 8px;
      }
      input[type="checkbox"]:checked {
        background-color: $primary-color;
        border-color: $dark;
      }	
    }
  }

}