@import '../../../styles/variables.scss';

.container {
  width: 100%;
  animation-name: fade;
  animation-duration: 0.2s;
  border-bottom: 1px solid rgba($black , 0.2);
  &.is-open {
    .title {
      border-bottom: 1px solid rgba($black , 0.2);
    }
  }
  @include animation-mixin-y(fade , 30px , 0px);
  .title {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    h2 {
      @include bold;
      text-transform: uppercase;
    }
    button {
      @include btn;
      width: 38px;
      height: 38px;
      display: flex;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: rgba($black , 0.1);
      }
    }
  }
  .panel {
    overflow: hidden;
    @include transition;
    .content {
      @include transition;
    }
  }
}