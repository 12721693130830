@import '../../../../styles/variables.scss';

.container {
  width: 100%;
  padding-left: 10px;
  @media only screen and (min-width: 1150px) {
    padding-left: 60px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid rgba($black , 0.2);
  }
  .title {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    h2 {
      @include bold;
      text-transform: uppercase;
    }
    button {
      @include btn;
      width: 38px;
      height: 38px;
      display: flex;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: rgba($black , 0.1);
      }
    }
  }
  .panel {
    overflow: hidden;
    @include transition;
    .content {
      padding-bottom: 20px;
      .labels {
        display: flex;
        justify-content: flex-end;
        height: 36px;
        .col {
          width: 20%;
          display: flex;
          align-items: center;
          padding-left: 10px;
          border-left: 2px solid #D5D5DA;
          p {
            margin: 0px;
          }
          @include label;
        }
      }
    }
  }
}