@import '../../../styles/variables.scss';

.container {
  padding: 20px 20px;
  .row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
    .col {
      width: 100%;
      &.user {
        margin-bottom: 30px;
      }
    }
  }
  .delete {
    @include btn;
    display: flex;
    align-items: center;
    padding: 0px;
    @include label;
    margin-top: 20px;
    color: rgba($black, 0.3);
    &:hover {
      color: $danger;
    }
    svg {
      transform: translateY(-2px);
    }
    p {
      margin: 0px 8px;
    }
  }
}