@import '../../../styles/variables.scss';

.container {
  display: flex;
  justify-content: space-between;
  margin: 140px 60px 60px 60px;
  width: calc(100% - 120px);
  background-color: transparent;
  .col {
    width: calc(100% - 420px);
    height: 100%;
    overflow-y: auto;
    @include scrollbar;
    overflow-x: hidden;
    background-color: $white;
    border-radius: $radius;
    box-shadow: $shadow;
    &:first-child {
      width: 380px;
      padding: 0px;
      overflow-y: hidden;
      background-color: $white;
      border-radius: $radius;
      box-shadow: $shadow;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .profile {
        padding: 30px;
        h2 {
          margin: 0px;
          @include title-3;
        }
        p {
          color: #767690;
          margin: 6px 0px 0px 0px;
        }
        button {
          cursor: pointer;
          display: flex;
          align-items: center;
          background-color: transparent;
          border: none;
          padding: 0px;
          margin-top:  30px;
          p {
            @include title-4;
            margin: 0px 10px;
            color: $primary-color;
            text-decoration: underline;
            font-size: 16px;
          }
        }
      }
      .illu {
        transform: translateY(20px);
      }
    }
  }
}