@import '../../../../styles/variables.scss';

.table {
  width: 100%;
  height: 100%;
  padding: 35px 20px;
  table {
    width: 100%;
    border-spacing: 0px;
    font-size: 12px;
    th {
      border-left: 1px solid #D8D8D8;
      border-bottom: 1px solid #D8D8D8;
      padding: 7px 8px;
      @include bold;
      font-size: 12px;
      text-align: left;
    }
    td {
      border-left: 1px solid #D8D8D8;
      border-bottom: 1px solid #D8D8D8;
      padding: 10px;
      padding: 7px 8px;
      color: $dark;
      @include bold;
      font-size: 12px;
      &:not(:first-child) {
        width: 24%;
      }
    }
    thead {
      tr {
        th {
          color: #6C7688;
          &:first-child {
            border-left: 1px solid transparent;
          }
        }
      }
    }
    tbody {
      tr {
        th {
          &:first-child {
            border-left: 1px solid transparent;
          }
        }
      }
    }
  }
}