@import '../../../styles/variables.scss';

.container {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background: $white;
  @include text;
  &:nth-child(2n) {
    background: rgba($black, 0.05);
  }
  .col {
    width: 20%;
    height: 100%;
    text-align: right;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &.name {
      width: calc(60% - 10px );
      p {
        text-align: left;
      }
    }
    &.amount {
      display: flex;
      justify-content: space-between;
      padding: 0px 0px 0px 10px;
      button {
        @include btn;
        padding: 0px;
        color: rgba($black, 0.4);
        &:hover {
          color: $danger;
        }
      }
    }
    &:not(:last-child) {
      border-right: 2px solid #D5D5DA;
    }
  }
}