@import '../../../styles/variables.scss';

.container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  @include scrollbar;
  .block {
    width: 100%;
    height: calc(38vh);
    min-height: 360px;
    background-color: $white;
    border-radius: $radius;
    box-shadow: $shadow;
    &:not(:last-child) {
      margin-bottom: 30px;

    } 
  }
}