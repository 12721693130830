@import '../../styles/variables.scss';

.container {
  width: 100%;
  height: 100%;
  background-color: $dark;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/images/fond.png');
  form {
    width: 460px;
    @include animation-mixin-y(slideIn, 50px, 0);
    animation-name: slideIn;
    animation-duration: 1s;
    .logo {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      img {
        width: 260px;
      }
    }
    .btn-container {
      margin: 30px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.reset {
        justify-content: center;
        a {
          text-decoration: none;
        }
      }
      .forgot {
        @include btn;
        @include label;
        padding: 0px;
        color:rgba($white , 0.4);
        text-decoration: underline;
        &:hover {
          color: $white
        }
      }
    }
    .message {
      min-height: 36px;
      text-align: center;
      .error {
        color: $danger;
      }
    }
  }
}