@import '../../../styles/variables.scss';

$transition: .3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
$width: 52px;
$height: 22px;

.container {
  position: absolute;
  top: 148px;
  padding-left: 40px;
  opacity: 0;
  @include transition;
  &.active {
    opacity: 1;
  }
  .select {
    margin-bottom: 20px;
    max-width: 250px;
  }
  .structures {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    max-height: 250px;
    overflow-y: auto;
    @include scrollbar;
    @media screen and (max-height: 950px) {
      max-height: 240px;
    }
    .structure {
      @include bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #F9F9FA;
      border: 1.5px solid $primary-color;
      border-radius: 9px;
      padding: 6px 10px;
      min-height: 41px;
      margin-right: 0px;
      margin-bottom: 6px;
      p {
        margin: 0px;
      }
      button {
        @include btn;
        height: 25px;
        min-width: 25px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        padding: 0px;
        &:hover {
          background-color: #ECECF0;
        }
      }
    }
  }
  nav {
    display: flex;
    flex-wrap: wrap;
    max-width: 250px;
    margin-top: 0px;
    @media only screen and (min-height: 800px ) {
      margin-top: 60px;
    }
    a {
      @include transition;
      @include label;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 50%;
      font-size: 12px;
      text-decoration: none;
      text-align: center;
      color: #D5D5DA;
      padding: 4px;
      img {
        margin: 5px ;
        max-width: 25px;
        max-height: 25px;
      }
      @media only screen and (min-height: 700px ) {
        padding: 8px;
        font-size: 14px;
        img {
          margin: 10px ;
          max-width: 33px;
          max-height: 33px;
        }
      }
      &:hover {
        transform: scale(1.1);
      }
      &.active-link {
        color: $dark;
      }
    }
  }
}