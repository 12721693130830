@import '../../../styles/fonts-style.scss';
@import '../../../styles/variables.scss';

.container-input{
  font-family: sans-serif;
  position: relative;
  label{
    @include label;
  }

  &.inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+.input-text{
      margin-top: 10px;
    }
  }

  .input-text{
    @include transition;
    display: block;
    padding: 10px;
    border: none;
    outline: none;
    background-color: transparent;
    border-radius: 0px;
    width: 100%;
    min-width: 250px;
    box-sizing: border-box;
    @include text;
    &:focus{
      border: solid 1px $primary-color;
    }
    &::placeholder {
      @include text;
      color : #a3a3a3;
    }
  }

  button {
    @include btn;
    position: absolute;
    top: 41px;
    right: 10px;
    color: $white
  }

  &.primary {
    .input-text {
      border-radius: $radius;
      min-height: 50px;;
      background-color: rgba($white , 0.2);
      border: 2px solid #ECECF0;
      color: $black;
      @include label;
    }
    label {
      color: $black;
    }
  }

  &.login {
    .input-text {
      border-radius: $radius;
      min-height: 50px;;
      background-color: rgba($white , 0.2);
      border: solid 1px #dcdcdc;
      color: $white;
    }
    label {
      color:$white;
    }
  }

}
