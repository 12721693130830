@import '../../.././../../styles/variables.scss';

.container {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: $white;
  @include label;
  &:nth-child(2n) {
    background-color: rgba($black, 0.05);
  }
  .label {
    width: 20%;
    height: 100%;
    text-align: right;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    span {
      @include text;
      &.prev {
        display: none;
        @media only screen and (min-width: 1670px) {
          display: inline;
        }
      }
    }
  }
  .input {
    width: 20%;
    height: 100%;
    padding: 4px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-left: 2px solid #D5D5DA;
    input {
      text-align: right;
      background-color: transparent;
      border: none;
      padding: 8px;
      width: calc(100% - 20px);
      margin: 2px 2px 2px 8px;
      outline: none;
      border-radius: 6px;
      @include label;
      @include transition;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
      &:focus {
        background: rgba($primary-extra-light, 0.4);
      }
    }
    .unit {
      margin-right: 10px;
    }
  }
  
}