@import './variables.scss';

body, html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  color: $black;
  font-family: "Montserrat-Regular" , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

//MAIN-CONTAINERS

.app-container {
  position: relative;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
  overflow: hidden;
}
.page-container {
  position: relative;
  width: 100%;
  height: 100vh;
  .page-content {
    position: absolute;
    width: calc(100% - 450px);
    height: calc(100vh - 200px);
    margin: 140px 60px 60px 390px;
    &.visualisation {
      width: calc(100% - 390px);
      padding-right: 60px;
      padding-bottom: 60px;
      height: calc(100vh - 180px);
      margin: 180px 0px 0px 390px;
    }
    &.admin {
      margin: 140px 60px 60px 60px;
      width: calc(100% - 120px);
      background-color: $white;
      border-radius: $radius;
      box-shadow: $shadow;
    }
    &.profile {
      margin: 140px 60px 60px 60px;
      width: calc(100% - 120px);
    }
  }
}
 
