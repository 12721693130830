@import '../../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    text-align: center;
    @include title-1;
    color: $primary-color;
  }
  .input {
    width: 100%;
    margin-bottom: 20px ;
  }
}