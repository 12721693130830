@import '../../../styles/variables.scss';

.container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  @include scrollbar;
  .block {
    width: 100%;
    margin-bottom: 40px;
    @include white-box;
  }
  .row {
    width: 100%;
    height: calc(35vh);
    min-height: 340px;
    display: flex;
    justify-content: space-between;
    .col {
      width: calc(60% - 20px);
      height: 100%;
      @include white-box;
      &.ratio {
        width: calc(40% - 20px);
      }
    }
  }
}