@import '../../../styles/variables.scss';

.container-custom-date-picker{
  .react-datepicker-popper  {
    padding-top: 40px;
  }
  .react-datepicker {
    padding: 20px;
    box-shadow: $shadow;
    border: none;
    @include text; 
  }
  .react-datepicker__triangle{
    display: none;
  }

  .react-datepicker__navigation {
    .react-datepicker__navigation-icon {
      display: none;
    }
    border: none;
    height: 30px;
    width: 40px;
    &:nth-child(3){
      transform: scaleX(-1);
    }

    &:before,
    &:after{
      position: absolute;
      z-index: 2;
      content:"";
      top: 20px;
      display: block;
      width: 10px;
      height: 2px;
      background-color: $primary-color;
    }

    &:after{
      transform: translateY(3px) rotate(45deg);
    }
    &:before{
      transform: translateY(-3px) rotate(-45deg);
    }
  }

  .react-datepicker__header{
    background-color: white;
    border-bottom: 1px solid #d2d2d2;

    .react-datepicker__current-month{
      @include label;
      font-weight: bold;
      line-height: 20px;
      transform: translateY(-14px);
      color : $dark;
    }
  }
  .react-datepicker__day-names {
    color: $primary-color;
    .react-datepicker__day-name {
      text-transform: uppercase;
      color: $primary-color;
    }
  
  }

}
