@import '../../../styles/variables.scss';

.container {
  width: 100%;
  border-bottom: 1px solid rgba($black , 0.2);
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    h2 {
      @include bold;
      text-transform: uppercase;
      @include transition;
      &.primary {
        color: $primary-color;
      }
    }
    .buttons {
      display: flex;
      .panel-toggle {
        @include btn;
        width: 38px;
        height: 38px;
        display: flex;
        border-radius: 20px;
        margin-left: 10px;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: rgba($black , 0.1);
        }
      }
    }
  }
  .panel {
    overflow: hidden;
    @include transition;
    .content {
      padding-bottom: 20px;
      min-height: 100px;
    }
  }
}