@import '../../../styles/variables.scss';

.container {
  padding: 20px 40px;
  background-color: $white;
  border-radius: $radius;
  box-shadow: $shadow;
  overflow-y: auto;
  @include scrollbar;
  .content {
    padding-bottom: 20px;
    animation-name: fade;
    animation-duration: 0.2s;
    @include animation-mixin-y(fade , 30px , 0px);
    .labels {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      .label {
        width: calc(35% - 2px);
        height: 40px;
        padding: 4px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0px;
        @include label;
        &:not(:first-child) {
          border-left: 2px solid #D5D5DA;
        } 
      }
    }
  }
}